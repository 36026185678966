import React, { useState } from "react";

import { Link } from "react-router-dom";
import "./Main.css";
import {
  QueryClient,
  QueryClientProvider,
  useQuery,
} from "@tanstack/react-query";

import backSortingImage from "./back-sorting.png";

import RCListArray from "../res/RCListArray.json";

const queryClient = new QueryClient();

function RegionalCenter() {
  return (
    <div>
      <header className="sticky-top">
        <table class="table text-center">
          <tbody>
            <tr>
              <td id="rc" className="bg-white">
                <Link className="text-decoration-none">Regional Center</Link>

                <Link
                  onClick={() => {
                    writeToLocalStorage("rc", "");
                    window.location.reload();
                  }}
                >
                  <img
                    width="25"
                    height="25"
                    src={backSortingImage}
                    alt="back-sorting"
                    className="mx-2"
                  />
                </Link>
              </td>

              <td id="sub" className="bg-blue">
                <Link
                  to="/subject-wise"
                  className="text-decoration-none text-white"
                >
                  Subject Updates
                </Link>
              </td>
            </tr>
          </tbody>
        </table>
      </header>

      <RegionalCenterData />
    </div>
  );
}

function RegionalCenterData() {
  var [selectedOption, setSelectedOption] = useState(
    readFromLocalStorage("rc")
  );

  const handleSelectChange = (event) => {
    const value = event.target.value;
    setSelectedOption(value);
    console.log(`Selected option: ${value}`);
    writeToLocalStorage("rc", value);
    try {
      window.Android.writeValue(
        "regionalCenter",
        String(value).toLocaleUpperCase()
      );
    } catch (e) {}
  };

  if (RCListArray.includes(selectedOption)) {
    return (
      <>
        <QueryClientProvider client={queryClient}>
          <Data rc={selectedOption} client={queryClient} />
        </QueryClientProvider>
      </>
    );
  } else {
    return (
      <>
        <div>
          <center>
            <h4 htmlFor="mySelect" className="text-center p-2">
              Select Regional Center
            </h4>
            <select
              id="mySelect"
              value={selectedOption}
              onChange={handleSelectChange}
              className="w-75 p-2 mt-2 border-primary rounded"
            >
              <option value="" disabled>
                Select an option
              </option>

              {RCListArray.map((city) => {
                return (
                  <>
                    <option value={city}>
                      {String(city).toLocaleUpperCase()}
                    </option>
                  </>
                );
              })}
            </select>

            <p className="mt-5 text-danger p-3">
              Note: Select your Accurate Regional Center for upcoming
              Notifications.
            </p>
            <p className="p-3 w-75">
              Click on the above
              <img
                width="25"
                height="25"
                src={backSortingImage}
                alt="back-sorting"
                className="mx-1"
              />{" "}
              icon, to set/change the Regional Center
            </p>
          </center>
        </div>
      </>
    );
  }
}

function Data(props) {
  const { isLoading, error, data } = useQuery({
    queryKey: ["repoData"],
    queryFn: () =>
      fetch(`https://api.ignouplus.com/rc/api.php?city=${props.rc}`).then(
        (res) => res.json()
      ),
  });

  if (isLoading) return "Loading...";

  if (error) return "An error has occurred: " + error.message;

  return (
    <div className="container" id="body">
      <h6 className="text-center">
        {String(props.rc).toLocaleUpperCase()} Regional Center Updates
      </h6>

      {data
        .slice(0)
        .reverse()
        .map((result) => (
          <div className="my-4 p-2 border bg-white rounded col text-wrap">
            <div className="row">
              <div className="text-danger text-left px-3 col">
                <b>{result.date}</b>
              </div>
              <div className="col text-right">
                <a
                  onClick={() => {
                    try {
                      window.Android.shareApp();
                    } catch (e) {}
                  }}
                >
                  <img
                    src="https://img.icons8.com/color/48/sent--v1.png"
                    width={25}
                    height={30}
                  />
                </a>
              </div>
            </div>

            {/* Conditionally render the image div if result.image exists */}
            {result.image_url && (
              <div className="p-2">
                <center>
                  <img src={result.image_url} className="in-msg-img" />
                </center>
              </div>
            )}

            <div className="px-1">
              <b>{result.title}</b>
            </div>

            <div className="px-1" style={{ whiteSpace: "pre-line" }}>
              {result.description}
            </div>

            <a
              className="w-75 px-1 py-2 col-12 text-wrap text-primary"
              id="url"
              onClick={() => {
                showWebContent(result.url);
              }}
            >
              {result.url}
            </a>
          </div>
        ))}
    </div>
  );
}

function showWebContent(url) {
  if (url == "https://ignouplus.com/whatsapp") {
    try {
      window.Android.openWhatsApp();
    } catch (e) {}
  } else {
    try {
      window.Android.showWebContent(url);
    } catch (e) {}
  }
}

// Function to write data to local storage
function writeToLocalStorage(key, value) {
  if (typeof key === "string" && typeof value === "string") {
    try {
      localStorage.setItem(key, value);
    } catch (e) {
      return alert(
        "Kindly Update the App to use Latest Updates Facility, otherwise you miss the Latest Updates."
      );
    }
  } else {
    console.error("Both key and value must be strings.");
  }
}

// Function to read data from local storage
function readFromLocalStorage(key) {
  if (typeof key === "string") {
    try {
      return localStorage.getItem(key);
    } catch (e) {
      return alert(
        "Kindly Update the App to use Latest Updates Facility, otherwise you miss the Latest Updates."
      );
    }
  } else {
    console.error("Key must be a string.");
    return null;
  }
}

export default RegionalCenter;
