import React, { useState } from "react";

import SubjectListArray from "../res/RCListArray.json";

import { Link } from "react-router-dom";
import cs from "./comming-soom.jpg";
import "./Main.css";

import {
  QueryClient,
  QueryClientProvider,
  useQuery,
} from "@tanstack/react-query";

const queryClient = new QueryClient();

function SubjectWise() {
  // Example usage
  // writeToLocalStorage("username", "john_doe");
  // const username = readFromLocalStorage("username");
  // console.log(username); // Output: 'john_doe'

  return (
    <div>
      <header className="">
        <table class="table text-center">
          <tbody>
            <tr>
              <td id="rc" className="bg-blue">
                <Link to="/" className="text-decoration-none text-white">
                  Regional Center
                </Link>
              </td>

              <td id="sub" className="bg-white">
                <Link to="/subject-wise" className="text-decoration-none">
                  Subject Updates
                </Link>
                <Link
                  onClick={() => {
                    writeToLocalStorage("subject", "");
                    window.location.reload();
                  }}
                >
                  <img
                    width="25"
                    height="25"
                    src="https://img.icons8.com/color/48/back-sorting.png"
                    alt="back-sorting"
                    className="mx-2"
                  />
                </Link>
              </td>
            </tr>
          </tbody>
        </table>
      </header>

      {/* <SubjectList /> */}
      <img src={cs} className="fit-img" />
    </div>
  );
}

function SubjectList() {
  var [selectedOption, setSelectedOption] = useState(
    readFromLocalStorage("subject")
  );

  const handleSelectChange = (event) => {
    const value = event.target.value;
    setSelectedOption(value);
    console.log(`Selected option: ${value}`);
    writeToLocalStorage("subject", value);
  };

  if (SubjectListArray.includes(selectedOption)) {
    return (
      <>
        <button onClick={handleSelectChange} value={""}>
          reset
        </button>

        <QueryClientProvider client={queryClient}>
          <Data subject={selectedOption} client={queryClient} />
        </QueryClientProvider>
      </>
    );
  } else {
    return (
      <>
        <div>
          <label htmlFor="mySelect">Choose an option:</label>
          <select
            id="mySelect"
            value={selectedOption}
            onChange={handleSelectChange}
          >
            <option value="" disabled>
              Select an option
            </option>

            {SubjectListArray.map((city) => {
              return (
                <>
                  <option value={city}>{city}</option>
                </>
              );
            })}
          </select>
          <p>Selected Option: {selectedOption}</p>
        </div>
      </>
    );
  }
}

function Data(props) {
  const { isLoading, error, data } = useQuery({
    queryKey: ["repoData"],
    queryFn: () =>
      fetch(`https://api.ignouplus.com/rc/api.php?city=${props.subject}`).then(
        (res) => res.json()
      ),
  });

  if (isLoading) return "Loading...";

  if (error) return "An error has occurred: " + error.message;

  return (
    <div className="container" id="body">
      <h3 className="text-center">{props.subject}</h3>

      {data
        .slice(0)
        .reverse()
        .map((result) => (
          <div className="my-4 p-2 border bg-white rounded col text-wrap">
            <div className="row">
              <div className="text-danger text-left px-3 col">
                <b>{result.date}</b>
              </div>
              <div className="col text-right">
                <a
                  onClick={() => {
                    window.Android.shareApp();
                  }}
                >
                  <img
                    src="https://img.icons8.com/color/48/sent--v1.png"
                    width={25}
                    height={30}
                  />
                </a>
              </div>
            </div>

            {/* Conditionally render the image div if result.image exists */}
            {result.image && (
              <div className="p-2">
                <center>
                  <img src={result.image} className="in-msg-img" />
                </center>
              </div>
            )}

            <div className="px-1">
              <b>{result.title}</b>
            </div>

            <div className="px-1" style={{ whiteSpace: "pre-line" }}>
              {result.description}
            </div>

            <a
              className="w-75 px-1 py-2 col-12 text-wrap text-primary"
              id="url"
              onClick={() => {
                showWebContent(result.url);
              }}
            >
              {result.url}
            </a>
          </div>
        ))}
    </div>
  );
}

// Function to write data to local storage
function writeToLocalStorage(key, value) {
  if (typeof key === "string" && typeof value === "string") {
    localStorage.setItem(key, value);
  } else {
    console.error("Both key and value must be strings.");
  }
}

// Function to read data from local storage
function readFromLocalStorage(key) {
  if (typeof key === "string") {
    return localStorage.getItem(key);
  } else {
    console.error("Key must be a string.");
    return null;
  }
}

function showWebContent(url) {
  if (url != null) {
    window.Android.showWebContent(url);
  }
}

export default SubjectWise;
