import RegionalCenter from "./components/RegionalCenter";
import SubjectWise from "./components/SubjectWise";
import logo from "./logo.svg";
import { BrowserRouter as Router, Route, Routes } from "react-router-dom";

function App() {
  return (
    <div>
      <Routes>
        <Route path="/" element={<RegionalCenter />} />
        <Route path="/subject-wise" element={<SubjectWise />} />
      </Routes>
    </div>
  );
}

export default App;
